<!-- 用户组管理 -->
<style scoped lang="less">
.title {
  padding: 10px 15px 0;
  font-size: 20px;
}
.content {
  padding: 0;
}
.layout-content {
  min-height: 200px;
  overflow-y: auto;
  background: #fff;
  border-radius: 4px;
  position: relative;
}
.right-content {
  padding: 0 15px 15px 15px;
}
.title_parent {
  height: 30px;
  background-color: #464c5b;
  margin-bottom: 20px;
  border-radius: 3px;
  .title_c {
    color: white;
    font-size: 15px;
    margin-left: 10px;
    line-height: 30px;
  }
}

.button {
  margin-right: 5px;
}

.groupTitles {
  font-size: 20px;
  color: red;
}
</style>
<template>
  <div class="content">
    <Row type="flex">
      <Col span="4">
        <!--<AutoComplete-->
        <!--@on-search="handleSearchGroup"-->
        <!--@on-select="handleSelect"-->
        <!--placeholder="输入用户组名称"-->
        <!--:transfer="true">-->
        <!--<Option v-for="item in searchGroups" :value="item.name" :key="item.name">{{ item.name }}</Option>-->
        <!--</AutoComplete>-->
        <Menu
          :theme="theme3"
          :active-name="openMenu"
          width="auto"
          @on-select="itemSelect"
        >
          <div class="layout-content" :style="left_content">
            <MenuGroup title="用户组">
              <MenuItem
                v-for="item in groups"
                :key="item._id"
                :name="item.name"
              >
                {{ item.name }}
              </MenuItem>
            </MenuGroup>
          </div>
        </Menu>
      </Col>
      <Col span="20">
        <div id="">
          <div class="search-top title" slot="title">
            <div class="input-group-inline">
              <label>用户组：</label>
              <Input
                v-model="curGroup.name"
                class="custom-control-width"
                :disabled="edit == 0"
                placeholder="请输入用户组名称"
                :maxlength="20"
              ></Input>
            </div>
            <div class="input-group-inline right clearfix">
              <div v-if="edit == 0">
                <Button
                  slot="extra"
                  v-if="checkAuth('user_group-add')"
                  class="button"
                  type="warning"
                  @click="addGroup"
                  >新增
                </Button>
                <Button
                  slot="extra"
                  v-if="checkAuth('user_group-edit')"
                  class="button"
                  type="primary"
                  @click="editGroup"
                  >编辑
                </Button>
                <Button
                  slot="extra"
                  v-if="checkAuth('user_group-delete')"
                  class="button"
                  type="error"
                  @click="deleteGroup"
                  >删除
                </Button>
              </div>
              <div v-if="edit == 1 || edit == 2">
                <Button slot="extra" class="button" type="warning" @click="save"
                  >保存</Button
                >
                <Button slot="extra" class="button" @click="cancel"
                  >取消</Button
                >
              </div>
            </div>
          </div>
          <div class="layout-content right-content" :style="c_style">
            <Form :model="curGroup" :label-width="120">
              <div
                v-for="(title, index) in groupTitles"
                :key="index"
                v-show="groupTitles.length != 0"
              >
                <div class="title_parent">
                  <div class="title_c">{{ title }}</div>
                </div>

                <template v-for="(item, index) in curGroup.authList">
                  <div
                    :key="index"
                    v-if="
                      (item.status == 1 && name != 'admin') ||
                        name == 'admin' ||
                        userGroup == '超级管理'
                    "
                  >
                    <FormItem
                      v-if="title == item.parent"
                      :label="`${item.name}：`"
                    >
                      <Checkbox
                        v-for="(one, index) in item.auth"
                        :key="index"
                        v-model="one.status"
                        :disabled="edit == 0"
                        :true-value="1"
                        :false-value="0"
                        >{{ one.name }}
                      </Checkbox>
                    </FormItem>
                  </div>
                </template>
              </div>
              <div v-if="groupTitles.length == 0">
                <p class="groupTitles">{{ noDataPromit }}</p>
              </div>
            </Form>
          </div>
        </div>
      </Col>
    </Row>
  </div>
</template>

<script>
import { ajax, checkAuth, deepClone, isEmpty } from "../../libs/util";
import Cookies from "js-cookie";

const _ = require("lodash");
const list_service = "/userGroup/auth";
const service = "/userGroup";
export default {
  data() {
    return {
      checkAuth: checkAuth,
      c_style: {
        height: `${window.innerHeight - 117}px`,
      },
      left_content: {
        height: `${window.innerHeight - 64}px`,
      },
      theme3: "light", //  Menu皮肤
      searchGroups: [], //  搜索列表
      openMenu: "",
      groups: [], //  用户组列表
      curGroup: {
        name: "", // 当前选中用户组名称
        _id: "", // 当前用户组id
        authList: [], // 当前用户组权限
      },
      groupTitles: [],
      lastGroup: {},
      edit: 0, // edit 0：默认状态，1：新增，2：编辑
      name: Cookies.get("name"), // 用户登录账号
      userGroup: Cookies.get("userGroup"),
      noDataPromit: "",
    };
  },
  methods: {
    itemSelect(name) {
      // 获取选择的MenuItem 的name属性
      let that = this;
      that.edit = 0;
      that.groups.forEach((item) => {
        if (that.curGroup.name != name) {
          // name不能重复
          that.curGroup = deepClone(item);
          that.setGroupTitle();
          that.openMenu = that.curGroup.name;
          return;
        }
      });
    },
    setGroupTitle() {
      let that = this;
      that.groupTitles = [];
      that.curGroup.authList.forEach((auth, index) => {
        if (isEmpty(auth.status)) {
          // 历史数据无status,设置一个默认值0
          auth.status = 0;
        }
        if (that.name == "admin" || Cookies.get("userGroup") == "超级管理") {
          // 如果用户是管理员，显示所有parent
          that.pushTitle(auth);
        } else {
          // 如果非管理员登录，需要判断status，为1时候才显示
          if (auth.status == 1) {
            // 为1时，才显示
            that.pushTitle(auth);
          }
        }
        if (index == that.curGroup.authList.length - 1) {
          if (that.groupTitles.length == 0) {
            that.noDataPromit = "暂无可管理权限";
          }
        }
      });
    },
    pushTitle(auth) {
      let that = this;
      if (that.groupTitles.length == 0) {
        // 空数组
        that.groupTitles.push(auth.parent);
      } else {
        if (!that.contains(that.groupTitles, auth.parent)) {
          that.groupTitles.push(auth.parent);
        }
      }
    },
    contains(arr, obj) {
      var i = arr.length;
      while (i--) {
        if (arr[i] === obj) {
          return true;
        }
      }
      return false;
    },
    // selectChanged(item) {
    //     if (item.status == 0) {   // 如果item设置为不可管理，则吧item中的所有的权限设置为0
    //         _.forEach(item.auth, (curAuth) => {
    //             if (curAuth.status == 1) {
    //                 curAuth.status = 0;
    //             }
    //         });
    //     }
    // },
    addGroup() {
      // 新增用户组
      let that = this;
      that.edit = 1;
      that.lastGroup = deepClone(that.curGroup);
      if (that.name == "admin") {
        // 如果为admin，则拉取所有用户权限
        that.getPermissions(); // 获取可设置权限列表
      } else {
        // 如果未非admin，直接拿已有的权限
        that.curGroup.name = "";
        that.curGroup.authList.forEach((item) => {
          item.auth.forEach((curAuth) => {
            curAuth.status = 0;
          });
        });
      }
    },
    editGroup() {
      let that = this;
      that.edit = 2;
      that.lastGroup = deepClone(that.curGroup);
      that.getPermissions();
    },
    deleteGroup() {
      let that = this;
      if (Cookies.get("userGroup") == that.curGroup.name) {
        // 不能删除当前用户所在的用户组
        that.$Modal.warning({
          title: "警告",
          content:
            "禁止删除当前登录账号所属的用户组，如果您确认删除，请切换用户组之后，再次操作！",
        });
        return;
      }
      let url = `${service}/${that.curGroup._id}`;
      that.$Modal.confirm({
        title: "操作提示",
        content: `确认删除用户组${that.curGroup.name}吗？`,
        okText: "确认",
        onOk: () => {
          ajax.delete(
            url,
            (res) => {
              that.fetchGroupList();
              that.$Message.success(`用户组${that.curGroup.name}删除成功`);
            },
            (err) => {
              that.$Message.error(err.message || "服务器异常，请重试");
            }
          );
        },
        onCancel: () => {},
      });
    },
    save() {
      // 用户组保存
      let that = this;
      if (isEmpty(that.curGroup.name)) {
        that.$Message.info("请输入用户组名称，再保存");
        return;
      }
      let isHave = _.find(that.groups, (item) => {
        return item.name === that.curGroup.name;
      });
      if (that.edit == 1) {
        if (isHave) {
          that.$Message.info("该用户组名称已存在");
          return;
        }
        ajax.post(
          service,
          {
            name: that.curGroup.name,
            authList: that.curGroup.authList,
          },
          (res) => {
            that.fetchGroupList(that.curGroup.name);
            that.edit = 0;
            that.$Message.success(`用户组${that.curGroup.name}新增成功`);
          },
          (error) => {
            that.$Message.error(error.message || "服务器异常，请重试");
          }
        );
      } else {
        if (that.curGroup.name !== that.lastGroup.name && isHave) {
          that.$Message.info("该用户组名称已存在");
          return;
        }
        ajax.put(
          `${service}/${that.curGroup._id}`,
          {
            name: that.curGroup.name,
            authList: that.curGroup.authList,
          },
          (res) => {
            that.fetchGroupList(that.curGroup.name);
            that.edit = 0;
            that.$Message.success(`用户组${that.curGroup.name}编辑成功`);
          },
          (error) => {
            that.$Message.error(error.message || "服务器异常，请重试");
          }
        );
      }
    },
    cancel() {
      let that = this;
      that.edit = 0;
      that.curGroup = that.lastGroup;
      that.setGroupTitle();
    },
    // handleSearchGroup(value) {
    //     let that = this;
    //     that.searchGroups = that.groups.filter((item) => {
    //         return value.length != 0 && item.name.substring(0, value.length) == value;
    //     })
    // },
    // handleSelect(value) {
    //     // 切换到选中的用户组
    //     this.itemSelect(value);
    // },
    fetchGroupList(value) {
      // 获取用户组列表,传detail时候返回详情
      let that = this;
      let url = `${service}?detail=true`;
      ajax.get(
        url,
        (res) => {
          if (res.data.list && res.data.list.length > 0) {
            if (value) {
              // 编辑完成之后存在的，选中
              that.groups = res.data.list;
              that.groups.forEach((item) => {
                if (value == item.name) {
                  // 选中的数据
                  that.openMenu = item.name;
                  that.curGroup = deepClone(item);
                  that.setGroupTitle();
                  return;
                }
              });
            } else {
              // 否则默认选中第一条
              that.groups = res.data.list;
              that.openMenu = that.groups[0].name;
              that.curGroup = deepClone(res.data.list[0]);
              that.setGroupTitle();
            }
          }
        },
        (error) => {
          that.$Message.error(error.message || "服务器异常，请重试");
        }
      );
    },
    getPermissions() {
      //获取可设置权限列表，新增时候使用
      let that = this;
      that.loading = true;
      ajax.get(
        list_service,
        (res) => {
          if (that.edit == 2) {
            // 编辑时候
            let auths = deepClone(that.curGroup.authList);
            _.forEach(auths, (item, index) => {
              let s = _.find(res.data, { key: item.key }); // 已设置的列表项中的key在默认配置文件中是否存在
              if (!s) {
                // 如果默认配置中不存在该项
                _.remove(that.curGroup.authList, (n) => {
                  // 移除已设置列表中有而默认列表中没有的配置项
                  return n.key == item.key;
                });
              } else {
                /* 如果默认配置中存在该已设置项，遍历查看auth中的具体权限项，看是否存在于默认配置中
                 */
                var temp = _.filter(that.curGroup.authList, (i) => {
                  // 查找到已设置项中当前key的auth项
                  return i.key == s.key;
                });
                _.forEach(item.auth, (t, i) => {
                  let ss = _.find(s.auth, { key: t.key }); // 遍历auth中的权限项目
                  if (!ss) {
                    _.remove(temp[0].auth, (n) => {
                      // 移除已设置列表中有而默认列表中没有的配置项
                      return t.key == n.key;
                    });
                  }
                });
              }
            });
          } else if (that.edit == 1) {
            // 新增时候
            that.curGroup.authList = deepClone(res.data);
            that.curGroup.name = "";
          }
          that.setGroupTitle();
        },
        (error) => {
          that.$Message.error(error.message || "服务器异常，请重试");
        }
      );
    },
  },
  mounted() {
    this.fetchGroupList();
  },
};
</script>
